<template>
  <section>
    <div class="app-width">
      <h1>For video studios, ad agencies,<span />and video professionals.</h1>
      <h3 class="center">
        The essential tool for visual storytellers.
      </h3>
    </div>
    <div class="coverflow">
      <img src="/img/storyfolder-desktop-windows.png" loading="lazy" />
    </div>
    <div class="app-width">
      <div class="featurettes">
        <Featurette
          icon="arrow"
          title="Import from anywhere."
          description="Quickly import videos from YouTube, Vimeo, or video file." />
        <Featurette
            icon="pencil"
            title="Break it down."
            description="Use folders, favorites, tags and metadata to organize your shots however you need." />
        <Featurette
          icon="link"
          title="Export and share your insight."
          description="Export your notes, storyboards, screengrabs, shot lists, spreadsheets and more." />
      </div>
    </div>
  </section>
</template>
<script lang="ts">
  import Vue from 'vue';
  import Featurette from "@/components/molecules/Featurette.vue";

  export default Vue.extend({
    components: {
      Featurette
    }
  });
</script>
<style scoped lang="less">
  @import '../../less/variables';
  section{
    background: -webkit-linear-gradient(90deg, rgba(0,0,0,0), rgba(0,0,0,1));
  }
  .app-width{
    padding-top: 0.5em;
    padding-bottom: 0.5em;
  }
  h1{
    margin: 1.25em 0 0.5em 0;
    text-align: center;
  }
  .featurettes{
    display: grid;
    grid-template-columns: 1fr;
    align-items: start;
    margin-top: 1em;
    margin-bottom: 3em;
    gap: 1em;
  }
  .featurette{
    margin-bottom: 1.5em;
  }
  h3{
    margin-bottom: 1em;
    text-align: center;
  }

  .coverflow{
    text-align: center;
    margin: 2em 0;
    img{
      width: 80%;
    }
  }
  @media(min-width: @firstbreakpoint){
    .coverflow img{
      width: 80%;
    }
  }
  @media(min-width: @thirdbreakpoint){
    .featurette{
      font-size: 1.5rem;
    }
    .featurettes{
      grid-template-columns: 1fr 1fr 1fr;
      margin-bottom: 7em;
    }
    h1 span, h3 span{
      display: block;
    }
  }
</style>
