<template>
  <section class="hero">
    <div class="above-the-fold app-width">
      <a href="https://blog.storyfolder.com/new-ai-powered-video-notes/" target="_blank">
        <div class="feature">
          <div class="feature-icon">
            <span class="tag ai">AI</span>
          </div>
          <div class="content">
            <strong>
              New video notes editor with AI discovery assistant
            </strong>
            <p>
              Discover essential storytelling and production elements that take your work to the next level.
            </p>
          </div>
          <div class="arrow-container">
            <IconSvg name="chevron-arrow" />
          </div>
        </div>
      </a>
      <div class="side-by-side">
        <div>
          <h1 class="main">
            Convert any video to
            <div class="gradient">
              <span v-if="false">Storyboards and</span>
              <TextRotator :data="spans" :timer="2.75" :infinite="true"/>
            </div>
          </h1>
          <h3>For Video Studios, Agencies and Filmmakers</h3>
          <ol>
            <li class="first">
              Import a video from YouTube, Vimeo or a file
            </li>
            <li class="first">
              Highlight key moments and elements
            </li>
            <li class="first">
              Add all of your notes and details
            </li>
            <li class="first">
              Share storyboards, notes, plans, pitches, estimates, and more.
            </li>
          </ol>
          <br />
          <Button class="special rounded" :label="buttonLabel" icon="arrow" @click="$router.push({name: 'signup'})"/>
          <p class="os-list">
            Available for <span><IconSvg name="apple" /> macOS</span> and <span><IconSvg name="windows" /> Windows</span>.
          </p>
        </div>
        <img src="/img/explainer.png" width="70%" v-if="false"/>
        <div class="video-container">
          <video playsinline muted loop controls width="100%" ref="video">
            <source src="/storyfolder-explainer.mp4" />
          </video>
          <Button label="See How It Works" class="rounded secondary" icon="play" @click="play" v-if="!playing"/>
        </div>
      </div>
    </div>
  </section>
</template>
<script lang="ts">
  import Vue from 'vue';
  import IconSvg from "@/components/atoms/IconSvg.vue";
  import Button from "@/components/atoms/Button.vue";
  import TextRotator from "@/components/atoms/TextRotator.vue";

  export default Vue.extend({
    components: {
      Button,
      IconSvg,
      TextRotator
    },
    props: {

    },
    data(){
      return {
        playing: false,
        timeout: null,
        spans: [
          // 'Notes',
          // 'Insights',
          // 'Plans',
          // 'Slides',
          // 'Pitches',
          // 'Analysis',
          // 'Estimates'
          'Instant Storyboards',
          'Notes and Knowledge',
          'Skills and Techniques',
          'Blueprints & Pitches'
        ]
      }
    },
    computed: {
      buttonLabel(){
        if(this.$root.os === 'mac'){
          return 'Download Free macOS app'
        }else if(this.$root.os === 'windows') {
          return 'Download Free Windows app'
        }else{
          return 'Download Free App'
        }
      }
    },
    mounted(){
      // when the video plays, set playing to true
      this.$refs.video.addEventListener('play', () => {
        this.playing = true;
      });
      // when the video pauses, set playing to false
      this.$refs.video.addEventListener('pause', () => {
        this.playing = false;
      });
    },
    methods: {
      play(){
        this.$refs.video.play();
        this.playing = true;
      }
    }
  });
</script>
<style scoped lang="less">
  @import '../../less/variables';
  section {
    overflow: hidden;
    border-bottom: 0;
    position: relative;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0.5) 100%);
    display: flex;
  }
  .above-the-fold{
    position: relative;
    z-index: 1;
    text-shadow: 0 0 20px rgba(0,0,0,1);
    padding-top: 70px;
    padding-bottom: 8vh;
  }
  .side-by-side{
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    gap: 2rem;
    text-align: center;
    margin-top: 3em;
  }
  h1{
    margin-top: 0;
    line-height: 1.1em;
    padding-bottom: 0.8em;
    font-size: 1.9rem;
    overflow: visible;
    div.gradient{
      white-space: nowrap;
    }
  }
  ol{
    font-size: 1.1em;
    margin-bottom: 2em;
    list-style: none;
    counter-reset: li;
    padding-left: 1.5em;
    display: inline-block;
    li{
      margin-bottom:0.5em;
      color: white;
      font-weight: 500;
      padding-left: 1.25em;
      &:before{
        content: counter(li) + '.';
        color: @grayMedium;
        display: inline-block;
        width: 1em;
        margin-left:-1.25em;
        counter-increment: li;
      }
    }
  }
  p{
      margin:0 auto 1em auto;
      line-height: 1.5em;
      &.first{
        font-size: 1.25rem;
        color: white;
        font-weight: 500;
      }
  }

  .feature{
    background-image: url(/img/backgrounds/prism-background.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    padding: 1em;
    border-radius: 0.5em;
    font-size: 0.9rem;
    display: grid;
    display: none;
    grid-template-columns: 1fr;
    gap: 1em;
    margin-bottom: 2em;
    p{
      font-size: inherit;
      margin: 0;
      color: @white;
    }
    .feature-icon{
      display: none;
    }
    span.tag.ai{
      background: @purpleVibrant;
      font-weight: 600;
      color: @white;
      text-transform: uppercase;
      padding: 0.25em 0.5em;
      border-radius: 0.25em;
      font-size: 24px;
      box-shadow: 0px 0px 35px fade(@black, 50%);
    }
    .arrow-container{
      display: flex;
      align-items: center;
    }
  }

  .hero-image{
    width: 100%;
  }
  .gray{
    color: @grayMedium;
  }

  .os-list{
    margin: 1em auto 0 auto;
    font-size: 80%;
    .icon{
      zoom: 1.3;
    }
    span{
      white-space: nowrap;
    }
  }

  .video-container {
    position: relative;
    button{
      position: absolute;
      top: 50%;
      left: 50%;
      width: 15em;
      margin-top: -2em;
      margin-left: -7.5em;
      background: rgba(0,0,0,0.7);
      transition: none;
      &:hover{
        opacity: 1;
        background: rgba(0,0,0,0.9);
      }
    }
  }
  video{
    border-radius: 1rem;
    width: 100%;
    max-width: 1200px;
    cursor: pointer;
    aspect-ratio: 1.655/1;
  }

  @media(min-width: @zeroethbreakpoint){
    h1{
      padding-bottom: 0.3em;
      font-size: 2.5rem;
    }
  }

  @media(min-width: @firstbreakpoint){
    button{
      width: auto;
      margin: 0;
      display: inline-block;
    }
    .hero-image{
      width: 90%;
    }
    h1{
      font-size: 2.75rem;
    }
    h3{
      font-size: 1.5rem;
    }
    .feature{
      grid-template-columns: 50px 1fr 50px;

      .feature-icon{
        display: block;
      }
    }
  }
  @media(min-width: @secondbreakpoint){
    h1{
      font-size: 3.5rem;
    }
    ul{
        font-size: 20px;
    }
  }
  @media(min-width: @thirdbreakpoint){
    ul{
        margin-bottom: 0;
    }
    p, p.first{
        font-size: 1.25em;
    }
    .above-the-fold{
      padding-top: 100px;
    }
    h3{
      font-size: 1.75rem;
    }
  }
  @media(min-width: @fourthbreakpoint){
    h1{
      font-size: 3.5rem;
    }
    .side-by-side{
      grid-template-columns: 55% 1fr;
      text-align: left;
      justify-content: center;
      align-items: flex-start;
    }
  }
  @media(min-width: @fifthbreakpoint){
    h1{
      font-size: 4rem;
    }
  }
</style>
