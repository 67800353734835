<template>
  <div class="container">
    <div class="text" v-html="currentWord" :style="mainStyleComputed" ref="text"/>
    <span class="text-underline" ref="line" :style="{width: lineWidth}"/>
  </div>
</template>

<script>
export default {
  name: "Textra",
  props: {
    data: {
      type: Array,
      required: true
    },
    filter: {
      type: String,
      default: "simple"
    },
    timer: {
      type: Number,
      default: 2
    },
    infinite: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      defaultStyle: "transition: opacity 0.4s;",
      currentWord: this.data[0],
      lineWidth: '100%',
      liStl: null,
      dataCounter: 0,
      animationID: null,
      filters: {
        simple: ["opacity:0.1;transition-delay:0s;", "opacity:1;transition-delay:0s;"],
        "bottom-top": [
          "transform:translateY(10px);opacity:0;",
          "transform:translateY(0px);opacity:1;"
        ],
        "top-bottom": [
          "transform:translateY(-10px);opacity:0;",
          "transform:translateY(0px);opacity:1;"
        ],
        "right-left": [
          "transform:translateX(10px);opacity:0;",
          "transform:translateX(0px);opacity:1;"
        ],
        "left-right": [
          "transform:translateX(-10px);opacity:0;",
          "transform:translateX(0px);opacity:1;"
        ],
        press: ["letter-spacing: 4px;opacity:0;", "opacity:1;"],
        scale: ["transform:scaleY(1.4);opacity:0;", "opacity:1;"],
        flash: [
          "transform:skewX(-70deg);opacity:0;",
          "transform:skewX(0deg);opacity:1;"
        ],
        flip: [
          "transform:rotateX(-180deg);opacity:0;",
          "transform:rotate(0deg);opacity:1;"
        ]
      }
    };
  },
  computed: {
    mainStyleComputed() {
      return this.defaultStyle + this.liStl;
    }
  },
  watch: {
    currentWord: {
      immediate: true,
      handler: function() {
        this.$nextTick(() => {
          this.updateLineWidth();
        });
      }
    }
  },
  created() {
    let previousTime = 0;
    const that = this;
    function run(currentTime) {
      if (previousTime + (this.timer*1000) < currentTime) {
        //hiding
        this.liStl = this.filters[this.filter][0];
      }
      if (previousTime + (this.timer*1000) + 250 < currentTime) {
        //showing
        previousTime = currentTime;
        this.currentWord = this.data[++this.dataCounter];

        this.liStl = this.filters[this.filter][1]
        if (this.dataCounter === this.data.length) {
          if (this.infinite) {
            this.dataCounter = 0;
            this.currentWord = this.data[this.dataCounter];
          } else {
            // stopping animation
            return;
          }
        }
      }
      this.animationID = window.requestAnimationFrame(run.bind(that));
    }
    this.animationID = window.requestAnimationFrame(run.bind(that));
    window.addEventListener("resize", this.updateLineWidth);
  },
  beforeDestroy(){
    window.cancelAnimationFrame(this.animationID);
    window.removeEventListener("resize", this.updateLineWidth);
  },
  methods:{
    updateLineWidth(){
      this.lineWidth = this.$refs.text.clientWidth + "px";
    }
  }
};
</script>

<style lang="less" scoped>
@import '../../less/variables';
.container{
  display: inline-block;
  position: relative;
  vertical-align: bottom;
  height: 1.1em;
}
.text {
  display: inline;
  position: absolute;
  top: 0;
  z-index: 1;
  background: -webkit-linear-gradient(45deg, #4797ff, #c567fa, #fc61d0, #fd5c47);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: none;
  left: 50%;
  transform: translateX(-50%);
}

@media(min-width: @fourthbreakpoint){
  .text{
    left: auto;
    transform: translateX(0);
  }
}
.text-underline{
  display: inline-block;
  height: 2px;
  background: -webkit-linear-gradient(45deg, #4797ff, #c567fa, #fc61d0, #fd5c47);
  transition: all 0.4s ease-out;
  position: relative;
  top: 0.2em;
  border-radius: 2px;
  opacity: 0.5;
  width: 100%;
}
</style>