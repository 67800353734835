import { render, staticRenderFns } from "./TheVideoSection.vue?vue&type=template&id=5c63d6fa&scoped=true"
import script from "./TheVideoSection.vue?vue&type=script&lang=ts"
export * from "./TheVideoSection.vue?vue&type=script&lang=ts"
import style0 from "./TheVideoSection.vue?vue&type=style&index=0&id=5c63d6fa&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5c63d6fa",
  null
  
)

export default component.exports