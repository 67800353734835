<template>
  <div class="use-case-container">
    <SectionTwoColumn class="app-width text-top alt">
      <div slot="graphic">
        <img src="/img/apple-homepod-storyboard.jpg" class="fancy"/>
      </div>
      <div slot="text">
        <h1>
          <span  class="color-2">
            <IconSvg name="heart" />
          </span>
          Improve client relations
        </h1>
        <p>
          Demystify the production process and manage expectations. Showcase your attention to detail and industry expertise.
        </p>
      </div>
    </SectionTwoColumn>
    <SectionTwoColumn class="app-width alt text-top">
      <div slot="graphic">
        <img src="/img/proofing.jpg" class="fancy"/>
      </div>
      <div slot="text">
        <h1>
          <span  class="color-6">
            <IconSvg name="circled-checkmark" />
          </span>
          Collaborate precisely.
        </h1>
        <p>
          Simplify feedback and proofing with fast note taking, custom QA fields, one-click sharing, and exports in multiple formats.
        </p>
      </div>
    </SectionTwoColumn>
  </div>
</template>
<script lang="ts">
  import Vue from 'vue';
  import SectionTwoColumn from "@/components/molecules/SectionTwoColumn.vue";
  import IconSvg from "@/components/atoms/IconSvg.vue";

  export default Vue.extend({
    components: {
      IconSvg,
      SectionTwoColumn

    }
  });
</script>
<style scoped lang="less">
  @import '../../less/variables';
  /deep/ section {
    margin: 10vh 0 ;
  }
  .use-case-container{
    padding: 4vh 0;
  }
</style>
