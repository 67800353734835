<template>
  <section>
    <div v-if="false">
      <h1 class="logo">
        <img src="/img/storyfolder-logo.svg"><br />
        StoryFolder<span>beta</span>
      </h1>
      <h2>For Desktop</h2>
      <Button class="special" label="Get Started for Free" size="large" :has-chevron="true" :to="$store.getters['user/isSignedIn'] ? {name:'AccountSubscriptionView'} : {name:'preorder'}" />
      <p>
        Application for Windows and macOS.
      </p>
    </div>
    <div>
      <TheStoryFolderLogo />
      <br />
      <br />
      <h1>Get Started Free</h1>
      <h2>Download the App.</h2>
      <Button class="special rounded" label="Try Free Now" size="large" :has-chevron="true" :to="{name: 'signup'}" />

    </div>
  </section>
</template>
<script lang="ts">
  import Vue from 'vue';
  import Button from "@/components/atoms/Button.vue";
  import TheStoryFolderLogo from "@/components/molecules/TheStoryFolderLogo.vue";

  export default Vue.extend({
    components: {
      TheStoryFolderLogo,
      Button
    }
  });
</script>
<style scoped lang="less">
  @import '../../less/variables';
  section {
    background-image: url(/img/backgrounds/prism-background.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    text-align: center;
    padding: 3rem 0;
  }
  .logo{
    font-family: helvetica, arial, sans-serif;
    font-weight: 600;
    span{
      font-size: 0.75em;
      text-transform: uppercase;
      font-weight: 300;
      opacity: 0.5;
    }
  }
  h2{
    margin:0;
  }
  p{
    color: @white;
    font-size: 90%;
    margin-top: 0;
  }
</style>
