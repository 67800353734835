<template>
  <section class="hero">
    <div class="app-width">
      <div class="featurettes" v-if="false">
        <Featurette
            icon="film"
            title="Import from any source"
            description="Import from YouTube, Vimeo, or a file. Unique shots are detected and laid out on an interactive storyboard." />
        <Featurette
            icon="pencil"
            title="Add your notes and thoughts"
            description="Organize all of your ideas and notes in one place for research and collaboration." />
        <Featurette
            icon="link"
            title="Export and share with a click"
            description="Share your storyboard and notes securely with a click, or export in multiple formats for seamless workflows." />
      </div>
      <div class="featurettes">
        <Featurette
            icon="film"
            title="Learn from existing videos"
            description="Unpack masterful videos to learn from top creatives. Leverage your insights to elevate your productions." />
        <Featurette
            icon="emoji"
            title="Impress clients"
            description="Share your thoughts on videos with clients. Showcase your attention to detail and industry knowledge with beautiful storyboards." />
        <Featurette
            icon="heart"
            title="Collaborate precisely"
            description="Simplify feedback and proofing with fast note taking, custom QA fields, one-click sharing, and exports in multiple formats." />
      </div>
    </div>
  </section>
</template>
<script lang="ts">
  import Vue from 'vue';
  import Featurette from "@/components/molecules/Featurette.vue";

  export default Vue.extend({
    components: {
      Featurette,
    }
  });
</script>
<style scoped lang="less">
  @import '../../less/variables';
  section {
    text-align: center;
    margin-top: 1em;
  }
  .video-container {
    position: relative;
    button{
      position: absolute;
      top: 50%;
      left: 50%;
      width: 15em;
      margin-top: -2em;
      margin-left: -7.5em;
    }
  }
  video{
    border-radius: 1rem;
    width: 100%;
    max-width: 1200px;
  }

  .featurettes{
    display: grid;
    grid-template-columns: 1fr;
    align-items: start;
    margin-top: 2em;
    margin-bottom: 3em;
    margin-right: auto;
    margin-left: auto;
    text-align: left;
    gap: 1em;
  }
  .featurette{
    margin-bottom: 1.5em;
    &:nth-child(5n){
      color: #fca87e;
    }
    &:nth-child(5n+1){
      color: #53c7e4;
    }
    &:nth-child(5n+2){
      color: #ceacf5;
    }
    &:nth-child(5n+3){
      color: #fa9ec5;
    }
    &:nth-child(5n+4){
      color: #fc7a7a;
    }
  }

  .use-cases span{
    display: inline;
    &:nth-child(5n){
      color: #fca87e;
    }
    &:nth-child(5n+1){
      color: #53c7e4;
    }
    &:nth-child(5n+2){
      color: #ceacf5;
    }
    &:nth-child(5n+3){
      color: #fa9ec5;
    }
    &:nth-child(5n+4){
      color: #fc7a7a;
    }
  }

  @media(min-width: @zeroethbreakpoint){
    h1{
      padding-top: 0.75em;
      padding-bottom: 0.3em;
      font-size: 2.5rem;
    }
  }

  @media(min-width: @firstbreakpoint){
    .narrow{
        max-width:80%;
        margin:0 auto;
    }
    button{
      width: auto;
      margin: 0 0.75rem;
      display: inline-block;
    }
    .hero-image{
      width: 90%;
    }
    h1{
      padding-top: 0.75em;
      padding-bottom: 0.3em;
      font-size: 2.75rem;
    }
  }
  @media(min-width: @secondbreakpoint){
    h1{
      font-size: 3.75rem;
      padding-bottom: 0.5em;
    }
    ul{
        font-size: 20px;
    }
    .featurette{
      font-size: 1.25rem;
    }
    .featurettes{
      width: 90% !important;
      margin-top: 4em;
    }
  }
  @media(min-width: @thirdbreakpoint){
    .above-the-fold{
      margin-bottom: 80px;
    }
    h1{
      font-size: 4.75rem;
    }
    ul{
        margin-bottom: 0;
    }
    .featurettes{
      grid-template-columns: 1fr 1fr 1fr;
      margin-bottom: 7em;
      margin-top: 7em;
    }
    .featurette{
      font-size: 1.5rem;
    }
    p, p.first{
        font-size: 1.25em;
    }
  }
  @media(min-width: @fourthbreakpoint){
    h1.main{
      font-size: 6rem;
    }
    p, p.first{
        font-size: 1.5em;
    }
  }
</style>
