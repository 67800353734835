<template>
  <section class="featurette-section">
    <div class="app-width">

      <img src="/img/storyfolder-desktop-hero.png" width="100%" loading="lazy" class="hero" v-if="false"/>
      <h1>Features</h1>

      <div class="featurettes-container">
        <Featurette
          icon="arrow"
          title="Import from YouTube + Vimeo"
          description="Import any scene, sequence, or video from your local file system, YouTube or Vimeo with a click." />
        <Featurette
            icon="gallery"
            title="Storyboard View"
            description="Videos are quickly and accurately broken down into interactive storyboards with exactly the notes you need." />
        <Featurette
            icon="magnifying-glass"
            title="Thumbnail View"
            description="See an entire production with every shot as a thumbnail at a glance." />
        <Featurette
            icon="pencil"
            title="Notes"
            description="Add notes for over 20 unique aspects of production including story, lighting, audio, direction, acting, symbolism, and more." />
        <Featurette
            icon="tag"
            title="Tags"
            description="Shot tags are a powerful way to group, organize, filter, and export your shots." />
        <Featurette
            icon="heart"
            title="Favorites and Filtering"
            description="Focus on the shots you care about with shot favorites, hiding, and filtering." />
        <Featurette
            icon="gear"
            title="Metadata"
            description="Shot size, movement, cameras, lenses, lighting, and other detailed metadata can be applied to any shot." />
        <Featurette
            icon="gear"
            title="Technical Metadata"
            description="Frame numbers, timecode, shot number, and even color data are automatically applied to every shot and included while exporting." />
        <Featurette
            icon="graph"
            title="Production Insights"
            description="Gain new insights on an edit or a production with detailed production metrics, graphs and charts." />
        <Featurette
            icon="gallery"
            title="Export Storyboards"
            description="Print and share beautiful PDF and HTML storybaords with rich, customizable metadata." />
        <Featurette
            icon="camera"
            title="Export Shot Lists"
            description="Export your shot data in CSV and XLSX formats compatible with Google Sheets, Excel, and other popular production tools for technical workflows." />
        <Featurette
            icon="play"
            title="Export Clips"
            description="Export clips as separate video files, named sequentially and logged with industry standard SMPTE timecodes for easy importing into your favorite editor." />
        <Featurette
            icon="library-inverted"
            title="Export Images"
            description="Export high-resolution poster frames for each shot in up to 8k resolution and sequentially named for easy importing into other industry tools." />
        <Featurette
            icon="moon"
            title="Engagement & Pacing"
            description="Visualize edit pacing and engagement with shot duration plotted on a timeline." />
        <Featurette
          icon="moon"
          title="Shot Duration Analysis"
          description="See average, median, shortest, and longest shot duration." />
        <Featurette
          icon="film"
          title="Custom Poster Frames"
          description="Select the prefect frame to represent each individual shot with a single click." />
        <Featurette
          icon="merge"
          title="Shot Splitting & Joining"
          description="Manually split or join shots with precision." />
        <Featurette
          icon="4-point-star"
          title="Color Effects"
          description="Remove color or boost contrast to visualize storyboards in a variety of ways." />
        <Featurette
          icon="home"
          title="Project Library"
          description="Stay organized automatically with all of your projects in one centralized library." />
        <Featurette
          icon="lock"
          title="Private and Secure"
          description="The StoryFolder desktop app lets you safely work with unreleased, high-security projects with confidence." />
        <Featurette
          icon="plus"
          title="More added regularly"
          description="All-new features are added regularly." />
      </div>
    </div>
  </section>
</template>
<script lang="ts">
  import Vue from 'vue';
  import Featurette from "@/components/molecules/Featurette.vue";

  export default Vue.extend({
    components: {
      Featurette
    }
  });
</script>
<style scoped lang="less">
  @import '../../less/variables';
  h1{
    margin-bottom: 1em;
  }
  .featurette-section{
    padding-top: 50px;
    text-align: center;
    padding-bottom: 7vw;
  }
  .featurettes-container{
    display: grid;
    grid-template-columns: 1fr;
    gap:1em;
    align-items: start;
  }
  .featurette{
    margin-bottom: 1.5em;
    text-align: left;
    &:nth-child(5n){
      color: #53c7e4;
    }
    &:nth-child(5n+1){
      color: #ceacf5;
    }
    &:nth-child(5n+2){
      color: #fa9ec5;
    }
    &:nth-child(5n+3){
      color: #fc7a7a;
    }
    &:nth-child(5n+4){
      color: #fca87e;
    }
  }
  .and-more{
    background: black;
    padding: 2em;
    border-radius: 1rem;
  }
  img.hero{
    border-radius: 1rem;
    margin-bottom: 2rem;
  }
  @media(min-width: @secondbreakpoint){
    .featurette-section{
    }
    .featurettes-container{
      grid-template-columns: 1fr 1fr;
      gap:2em;
    }
    /deep/ .featurette .title{
      font-size: 1.25em;
    }
  }
  @media(min-width: @thirdbreakpoint){
    .app-width {
      max-width: 80vw;
    }
    .featurettes-container{
      grid-template-columns: 1fr 1fr 1fr;
      gap:2em;
    }
    img.hero{
      margin-bottom: 6rem;
    }
  }
</style>
