<template>
  <section style="background: white;">
    <div class="app-width">
      <div style="text-align: center">
        <img src="/img/third-party-brands.png" class="brands"/>
        <h1>Industry trusted.</h1>
        <h2>Marketers, directors, and video teams at brands like Apple, Netflix, SONY, Showtime Networks, and others said:</h2>
      </div>
      <h2>
        <WordCloud>
          <span>StoryFolder is amazing! It opens up a whole new way of visualizing productions.</span>
          <span>The most innovative filmmaking tool I've seen in a long time.</span>
          <span>An awesome tool for any visual storyteller.</span>
          <span>I can see the story clearly and edit more efficiently.</span>
          <span>It's just so cool to dive deep on how my fav videos have been put together.</span>
          <span>StoryFolder saves me endless time creating screengrabs and shot lists.</span>
          <span>I am quite surprised by the possibilities.</span>
          <span>StoryFolder is fantastic! Such a time-saver.</span>
          <span>Far, far, far quicker than me doing the same job maually.</span>
          <span>Each tool is really useful.</span>
          <span>Intuitive and genius.</span>
          <span v-if="false">StoryFolder is a masterpiece.</span>
          <span>Amazing tool. Easier than any other way.</span>
          <span>It works like a charm. Such a great tool. Just a big value.</span>
          <span v-if="false">This will be an industry standard in no time.</span>


          <span v-if="false">I was able to make a beautiful contact sheet from our short film in seconds.</span>
          <span v-if="false">I can storyboard automatically and work much faster with StoryFolder.</span>
          <span v-if="false">StoryFolder helps me break down shots for bidding and VFX workflows.</span>
        </WordCloud>
      </h2>
    </div>
  </section>
</template>
<script lang="ts">
  import Vue from 'vue';
  import WordCloud from "@/components/molecules/WordCloud.vue";

  export default Vue.extend({
    components: {
      WordCloud
    }
  });
</script>
<style scoped lang="less">
  @import "../../less/variables";
  section{
    padding: 6vh 0 8vh 0;
  }
  h1{
    color: @black;
  }
  h2{
    font-size: 1.25em;
    color: @grayMedium;
  }
  .brands{
    max-width: 400px;
    display: block;
    width: 50%;
    margin:0 auto 1em auto;
  }

  @media(min-width: @firstbreakpoint){
    section{
      padding: 4em 0;
    }
  }
  @media(min-width: @secondbreakpoint){
    section{
      padding: 6vh 0 12vh 0;
    }
    .app-width{
      max-width: 700px;
    }
  }
</style>
