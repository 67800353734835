<template>
  <div class="logo">
    <img src="/img/storyfolder-logo.svg" class="logo">
    <span>
      StoryFolder
      <span class="beta" v-if="showBeta">Beta</span>
    </span>
  </div>
</template>
<script lang="ts">
  import Vue from 'vue';
  export default Vue.extend({
    props: {
      showBeta: {
        type: Boolean,
        default: false
      }
    }
  });
</script>
<style scoped lang="less">
  .logo{
    font-family: helvetica, arial, sans-serif;
    font-weight: 600;
    font-size: 1em;
    letter-spacing: -0.05em;
    align-items: center;
    gap: 0.2em;
    display: inline-flex;
    img {
      height: 1.25em;
      vertical-align: middle;
      position: relative;
      top: -.15em;
    }
    span.beta{
      font-size: 0.75em;
      text-transform: uppercase;
      font-weight: 300;
      opacity: 0.5;
    }
    &.stack{
      flex-direction: column;
      img{
        top: 0;
      }
    }
  }
</style>
