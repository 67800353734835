<template>
  <section>
    <div class="app-width">
      <h1>
        How does StoryFolder compare to {{thirdPartyToolName}}?
      </h1>
      <p class="h3 white">With StoryFolder you can:</p>
      <ul>
        <li>Import your own shots, scenes and films.</li>
        <li>View and interact with full-motion video (not just still images).</li>
        <li>Add your own notes, tags, metadata, and organization.</li>
        <li>Export in useful formats like storyboards, shot lists, spreadsheets, timecode, color data, and more.</li>
      </ul>
      <br />
      <h1 class="p">
        Searchable online databases of film images like <a href="https://film-grab.com" target="_blank">FilmGrab</a>, <a href="https://www.moviestillsdb.com/" target="_blank">MovieStillsDB</a> or <a href="https://shotdeck.com" target="_blank">{{thirdPartyToolName}}</a> are great resources - but StoryFolder is designed to be different.
      </h1>
    </div>
  </section>
</template>
<script lang="ts">
  import Vue from 'vue';

  export default Vue.extend({
    computed: {
        thirdPartyToolName(){
            if(this.$route.name === 'shot-deck'){
                return 'Shot Deck'
            }
            return 'ShotDeck'
        }
    }
  });
</script>
<style scoped lang="less">
  @import '../../less/variables';

  h2{
    color: #999999;
  }
  h1 a{
    color: inherit;
  }
  section{
    padding: 3rem 0;
  }
  @media(min-width: @firstbreakpoint){
    section{
      padding: 5rem 0;
    }
  }
  @media(min-width: @secondbreakpoint){
    section{
      padding: 10rem 0;
    }
  }
  @media(min-width: @thirdbreakpoint){
    section{
      padding: 12rem 0;
    }
  }
</style>
